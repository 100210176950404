import { useCallback, useContext, useEffect, useState } from "react";

import releaseNotesService from "./release-notes-service";
import useStatus from "../../generic/hooks/use-status/use-status";
import useSwitch from "../../generic/hooks/use-switch/use-switch";
import AnalyticsAppContext from "../master-layout/analytics-app-context";

const defaultService = releaseNotesService();

const useReleaseNotes = ({ service = defaultService }) => {
  const {
    status: releaseNotesStatus,
    setSavingStatus: loading,
    setErrorStatus: error,
    setResetStatus: clearStatus,
  } = useStatus({saving:true});
  const { analyticsClickEvent } = useContext(AnalyticsAppContext);

  const [releaseNotes, setReleaseNotes] = useState([]);
  const [releaseNotesOpen, openReleaseNotes, closeReleaseNotes] = useSwitch();

  const load = useCallback(async () => {
    const notes = await service.checkReleaseNotes();
    setReleaseNotes(notes);
  }, [service]);

  const onReleaseNotesOpen = useCallback(async () => {
    openReleaseNotes();
    analyticsClickEvent('release-notes-open');
    if (!releaseNotes?.length) {
      try {
        loading();
        const notes = await service.loadReleaseNotes();
        setReleaseNotes(notes);
        clearStatus();
      } catch (e) {
        error(e);
      }
    }
  }, [
    analyticsClickEvent,
    clearStatus,
    loading,
    error,
    openReleaseNotes,
    releaseNotes,
    service
  ]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    releaseNotes,
    releaseNotesStatus,
    releaseNotesOpen,
    openReleaseNotes: onReleaseNotesOpen,
    closeReleaseNotes,
  };
}

export default useReleaseNotes;